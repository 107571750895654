<template>
	<main>
		<HeaderTab :title="$t('pax.title_pax')" :swiper_tabs="swiper_tabs"/>

		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div>
							<router-view></router-view>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>


<script type="text/javascript">

	export default {
		name: "GestionPax",
		mixins: [],
		data () {
			return {
				swiper_tabs: [
					{
						id: 'traduction',
						label: 'pax.traduction',
						active: false,
						href : 'PAXTraduction'
					},
					{
						id: 'criteres',
						label: 'pax.criteres',
						active: false,
						href : 'PAXCriteres'
					}
				]
			}
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
